export default [
  {
    label: 'Photo',
    field: 'photo',
  },
  {
    label: 'Role',
    field: 'position',
  },
  {
    label: 'Order',
    field: 'order',
  },
  {
    label: 'Name',
    field: 'name',
  },
  {
    label: 'Action',
    field: 'action',
  },
]
